import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../images/title.png"
import PageTitle from "./PageTitle";
import MyDisplayPDF from "./MyDisplayPDF";
import HazardLeft from "../images/consignes/HazardLeft.gif"
import Left from "../images/consignes/Left.gif"
import Right from "../images/consignes/Right.gif"
import speed from "../images/consignes/speed.gif"
import Start from "../images/consignes/Start.gif"
import Stop from "../images/consignes/Stop.gif"

function Consignes() {
  return (
    <div className="Consignes App-header">
      <PageTitle Title={Title} text="Sécurité" />
      <Container className="App-Consignes">
        <Row>
          <Col className="App">
            <h1>LES SIGNES A CONNAITRE</h1>
            <img style={{backgroundColor: "white"}} src={Start} alt="" />
            <img style={{backgroundColor: "white"}} src={HazardLeft} alt="" />
            <img style={{backgroundColor: "white"}} src={Right} alt="" />
            <img style={{backgroundColor: "white"}} src={Stop} alt="" />
            <img style={{backgroundColor: "white"}} src={Left} alt="" />
            <img style={{backgroundColor: "white"}} src={speed} alt="" />
            <p>Livret sécurité</p>
            <MyDisplayPDF pdf='/data/doc/Secu.pdf' />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="App">AVANT LE DÉPART</h1>
            <ul className="text-sm-left">
              <li>Les motos doivent être bien entretenues et posséder des pneumatiques en bon état</li>
              <li>Vérifier :
                <ul>
                  <li>Les niveaux (huile, freins, carburant....)</li>
                  <li>Le bon état et la pression des pneus</li>
                  <li>Le bon état et le bon fonctionnement de l'éclairage (feux, clignotants....)</li>
                  <li>Le bon état des freins et de l'embrayage</li>
                </ul>
              </li>
              <li>Arrimer correctement vos bagages ou chargement. Répartir la charge pour éviter le délestage à l'avant</li>
              <li>Être résistant et reposé :
                <ul>
                  <li>Bien dormir, bien s'alimenter</li>
                  <li>Ne pas boire d'alcool</li>
                  <li>Attention aux médicaments qui risquent de modifier votre comportement</li>
                  <li>Si vous ne vous sentez pas d'"attaque" pour faire la route ne vous forcez pas</li>
                </ul>
              </li>
              <li>Vérifier la météo (prévoir équipement de pluie etc....)</li>
              <li className="bold red">Pour les ballades de 2 à X jours, ne pas oublier d'emporter :
                <ul>
                  <li>Combinaison de pluie, sur-bottes, sur-gants (pouvant également servir pour couper le froid)</li>
                  <li>Sac à bagages</li>
                  <li>Lunettes de jour et de nuit (jaunes ou blanches)</li>
                  <li>Sandows solides pour bien arrimer les bagages sur la moto
                    <br /> (attention à la chute de bagages, accident possible)
                  </li>
                  <li>1 à 2 bombes anti-crevaison (gros modèle)</li>
                  <li>Trousse à outils moto + Jeu d'ampoules d'éclairage moto de secours.</li>
                  <li>Trousse à pharmacie avec pansements, alcool à 90°, eau oxygénée, aspirine, nausicalm etc...</li>
                  <li>P.Q</li>
                  <li>Lampe poche</li>
                  <li>Couteau Suisse (ou assimilé)</li>
                  <li>Huile moteur pour contrôle réguliers des niveaux (tous les 500 km)</li>
                  <li>Sacs poubelles grands modèles pour protéger les bagages de la pluie</li>
                </ul>
              </li>
              <li>Documents :
                <ul>
                  <li>Pièce d'identité</li>
                  <li>Passeport (si nécessaire)</li>
                  <li>Carte grise de la moto</li>
                  <li>Vignette d'assurance</li>
                  <li>Permis de conduire</li>
                  <li>Carte HOG</li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="App">LE JOUR DU DÉPART</h1>
            <ul>
              <li>Venez 1/4 d'heure avant le départ</li>
              <li>Le plein de la moto effectué. En cas "d'oubli", prévenez le Safety officer ou Road Captain qui établira un arrêt du groupe pour y remédier</li>
              <li>Ecoutez le briefing avant le départ</li>
              <li>Les nouveaux membres ou invités se placeront derrière le Road-Captain en début de convoi</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="App">CONSIGNES SUR ROUTE</h1>
            <ul>
              <li>Restez à votre place dans la colonne</li>
              <li>Ne doublez pas les motos en amont (sauf agent de sécurité et <bold className="warning"> sauf en cas d'urgence</bold>) </li>
              <li>Ne dépassez pas le Road-Captain (sauf agent de sécurité)</li>
              <li>Lors d'un dépassement, rabattez-vous au plus vite à droite de la chaussée pour faciliter le dépassement de la moto qui vous suit</li>
              <li>Gardez le rythme, évitez de disloquer la colonne</li>
              <li>Attention aux intersections même protégées par les agents de sécurité</li>
              <li>Surveillez le plus souvent possible les feux stop des motos précédentes</li>
              <li>Soyez vigilant aux ralentissements brusques</li>
              <li>Attendez la moto qui vous suit ne la perdez pas de vue….</li>
              <li>Chaque moto est responsable de la moto qui la suit et doit l’attendre en cas de ralentissement ou de perte de vue. Ne cherchez pas à "<bold>coller</bold>" la moto qui vous précède mais <bold>surveillez</bold> la moto qui vous suit</li>
              <p className="bold underligne">Il y aura toujours une moto balai pour vous guider à bon port</p>
              <li>Ne quittez pas le groupe sans prévenir un responsable (road captain, safety officer...)</li>
              <li>Soyez cool, courtois envers les autres usagers de la route </li>
              <li>Adoptez une conduite responsable</li>
              <li>La Loi nous oblige à :
                <ul>
                  <li>Porter un casque homologué</li>
                  <li>Allumer les feux de croisement de jour comme de nuit</li>
                </ul>
              </li>
              <li>Respecter les limitations de vitesse : 90 Km/h sur route, 130 Km/h sur autoroute</li>
              <li>Réduire la vitesse et allonger les distances de sécurité en cas de pluie</li>
              <li>Respecter les distances de sécurité</li>
              <li>Faire attention aux traversées de villages : 50 Km/h !</li>
              <li>Rouler en quinconce, ne pas prendre 2 voies sur l’autoroute</li>
              <li>Se regrouper et réduire les distances en zone urbaine</li>
              <li>Regroupement après chaque péage sur l'autoroute</li>
              <li>Pendant les pauses, ne pas oublier de vérifier la chargement et la moto</li>
              <li>A l'arrivée sur un site, se garer proprement afin de ne pas gêner des usagers avec nos motos</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="App">LA LOI....RAPPEL</h1>
            <p>Voici un petit rappel en ce qui concerne la vitesse et les équipements obligatoires afin de pouvoir partir tranquille en cas de contrôle de police… !!!</p>
            <p className="red">• Les limitations de vitesse</p>
            <p>
              La vitesse, par beau temps est plafonnée sur autoroute à 130 Km/h
              Sur les chaussées séparées par un terre-plein, la limite est de 110 Km/h
              Et hors agglomération, sur les autres voies de circulation la limite est à 90 Km/h
            </p>

            <p>
              En cas de mauvais temps, les limitations tombent respectivement à 110, 100 et 80 Km/h.
              Dans le cas où la visibilité est inférieure à 50 m, la vitesse est alors limitée à 50 Km/h sur toutes les voies de circulation, mais dans ce cas mieux vaut éviter de prendre sa moto !
            </p>

            <p>En ville, la vitesse à respecter est de 50 Km/h, sauf à Paris où sur le périphérique par exemple, la loi autorise les motos à rouler à 80 Km/h.</p>


            <p>N’oublions pas non plus que lorsque les pics de pollution sont atteints, ces limitations peuvent être rabaissées.</p>

            <p>
              Si vous dépassez la limitation de vitesse de 50 Km/h vous serez passibles d’une amende de 1524,49 Euros (10 000 F), vous serez convoqués immédiatement devant un tribunal de police sans bénéficier du système de l’amende forfaitaire, plus un retrait de points.
              C’est ce qu’on appelle une amende de cinquième classe.
            </p>

            <p>Les excès de moins de 50 Km/h, ou amende de quatrième classe sont sanctionnés à hauteur de 762,25 Euros (5 000 F) plus un retrait de points.</p>


            <p className="red">• Les jeunes permis</p>

            <p>Pour nos amis novices, les limitations seront respectivement de :</p>

            <p>130 à <u>110</u> Km/h.</p>

            <p>110 à <u>100</u> Km/h.</p>

            <p>100 à <u>90</u> Km/h.</p>

            <p>Amusant…</p>
            <p>Il existe des contraventions pour excès de lenteur ! Par exemple, les motards qui rouleraient à moins de 80 km/h sur la voie de gauche de l’autoroute seraient passibles d’amendes.</p>


            <p>Vous pourrez être contrôlés légalement par la Police Nationale, la Gendarmerie et <u>la Police Municipale</u>.</p>


            <p className="red">• Les équipements obligatoires pour la moto</p>

            <p>
              Il ne faut jamais oublier que les règles concernant les caractéristiques de sa moto et de son équipement sont particulièrement strictes.
              Le seul fait de modifier ce qui a été déclaré lors de l’agrément de sa moto par le service des Mines sans faire une nouvelle réception à la DRIRE, constitue un risque face aux forces de l’ordre car la moto ne correspond plus à celle agrée par l’administration.
            </p>
            <p><b>Quelques petits rappels sur les équipements obligatoires :</b></p>
            <ul>
              <li>Un dispositif anti-parasite</li>
              <li>Au moins un rétroviseur côté gauche</li>
              <li>Un compteur de vitesse avec un indicateur kilométrique</li>
              <li>Au moins une béquille</li>
              <li>Un klaxon</li>
              <li>Deux freins</li>
              <li>Une plaque constructeur indiquant la marque, le numéro d’identification du constructeur, le niveau sonore de la moto au ralenti et le régime moteur auquel ce niveau sonore a été enregistré.</li>
            </ul>

            <p>Pour l’éclairage, il faut 2 feux clignotants à l’avant et à l’arrière, à l’avant 1 feu de croisement et un feu de route ; à l’arrière un feu d’arrêt rouge, un éclairage pour la plaque.</p>

            <p>Pour les pneus, les rainures doivent faire plus d’un millimètre de profondeur !!</p>

            <p>L’échappement doit être,  HOMOLOGUÉ !!! En cas de bruit excessif, les amendes vont de 68 Euros (environ 450 F) à 450 Euros (environ 3000 F) !!!</p>

            <p className="red">• Lorsque vous serez contrôlés, notez ces quelques conseils :</p>

            <p>- Il faut que l’agent utilise un sonomètre agréé, disposé à un mètre de hauteur et à 10 mètres du deux-roues bruyant.</p>

            <p>Inutile de rappeler que le dé-bridage coûte cher !!</p>

            <p>Également, n’oubliez pas que le casque homologué si possible, les gants de cuir, le blouson renforcé en cuir ou autre sont vraiment essentiels à votre sécurité.</p>





            <p>Nous vous réclamons une prudence accrue au guidon de votre moto.</p>

            <p>Il vaut mieux prévenir que de se retrouver à l’hôpital….</p>

            <p>BONNE ROUTE A TOUS</p>

            <p>&</p>

            <p>Merci de votre discipline</p>



            <p>Safety Officer</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Consignes;