import React from "react";
import { HashRouter as Router, Route, Switch, useParams } from "react-router-dom";
import {
  Navigation,
  Footer,
  Boutique,
  Liensutiles,
  Consignes,
  MyDisplayPDF,
  Concession,
  Home,
  Trombinoscope,
  Contacts,
  Activites,
  Bureau,
  Souvenirs
} from "./Components";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <Container fluid>
        <Row className="App-footer">
          <Router>
            <Navigation />
            <Col></Col>
            <Col xs={10} >
              <Switch>
                <Route path="/" exact component={() => <Home />} />
                <Route path="/bureau" exact component={() => <Bureau />} />
                <Route path="/contacts" exact component={() => <Contacts />} />
                <Route path="/activites" exact component={() => <Activites />} />
                <Route path="/trombinoscope" exact component={() => <Trombinoscope />} />
                <Route path="/souvenirs" exact component={() => <Souvenirs />} />
                <Route path="/souvenirs/:year" exact component={() => <Child />} />
                <Route path="/souvenirs/:year/:balade" exact component={() => <Child />} />
                <Route path="/concession" exact component={() => <Concession />} />
                <Route path="/boutique" exact component={() => <Boutique />} />
                <Route path="/adhesion" exact component={() => <MyDisplayPDF pdf='/data/doc/Adhesion.pdf' />} />
                <Route path="/consignes" exact component={() => <Consignes />} />
                <Route path="/CharteHOG" exact component={() => <MyDisplayPDF pdf='/data/doc/CharteHOG.pdf' />} />
                <Route path="/reglement" exact component={() => <MyDisplayPDF pdf='/data/doc/Reglt-Int-Chapter-Assoc.pdf' />} />
                <Route path="/statuts" exact component={() => <MyDisplayPDF pdf='/data/doc/Statuts.pdf' />} />
                <Route path="/liensutiles" exact component={() => <Liensutiles />} />
              </Switch>
            </Col>
            <Col></Col>
            <div className="App-footer"><br /><br /></div>
            <Footer />
          </Router>
        </Row>
      </Container>
    </div>
  );
}

function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { year, balade } = useParams();

  return <Souvenirs year={ year } balade={ balade } />;
}

export default App;