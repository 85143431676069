import React from "react";
import Title from "../images/title.png"
import FetchMembers from "./FetchMembers.js";
import PageTitle from "./PageTitle";

function Contacts() {
  return (
    <div className="Contacts App-header">
      <PageTitle Title={Title} text="Contacts" />
      <FetchMembers page='Contact' />
    </div>
  );
}

export default Contacts;