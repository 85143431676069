import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import fleur from '../images/divers/fleur01.png'
import renovation from '../images/divers/Renovation.png'
import pf from '../images/divers/PF.pdf'
import PageTitle from "./PageTitle";
import Title from "../images/title.png"

function Liensutiles() {
  return (
    <div className="Liensutiles App-header">
      <PageTitle Title={Title} text="Liens Utiles" />
      <Container fluid>
      <Row>
        <Col><a href="https://www.harley-davidson.com/fr/fr/content/hog.html" target="_blank" rel="noopener noreferrer">HOG France</a></Col>
        <Col><a href="https://www.harley-davidson-nice.com/" target="_blank" rel="noopener noreferrer">HD Factory</a></Col>
      </Row>
      <Row>
        <Col><a href="http://car-one.fr/" target="_blank" rel="noopener noreferrer">Carage Car One</a></Col>
        <Col><a href={fleur} target="_blank" rel="noopener noreferrer">Fleuriste</a></Col>
      </Row>
      <Row>
        <Col><a href={renovation} target="_blank" rel="noopener noreferrer">Travaux Rénovation</a></Col>
      </Row>
      <Row>
        <Col><a href="https://www.castellanigraveur.com/" target="_blank" rel="noopener noreferrer">Imprimeur Graveur</a></Col>
        <Col><a href={pf} target="_blank" rel="noopener noreferrer">Adresse Funeraire</a></Col>
      </Row>
      <Row>
        <Col><a href="https://cannes-cuisines.fr/" target="_blank" rel="noopener noreferrer">Cuisine</a></Col>
      </Row>
      <Row>
        <Col><a href="https://hogmerch.com/index.php/patch-pins/patches.html" target="_blank" rel="noopener noreferrer">Patches HOG</a></Col>
      </Row>
      </Container>
    </div>
  );
}

export default Liensutiles;