import React, { Component } from "react";
import Card from 'react-bootstrap/Card'

class PictureCard extends Component {

    render() {
        return (
            <div className='App-card'>
                <Card border="warning" style={{ width: '14rem', backgroundColor: 'black' }}>
                    <p><Card.Img variant="top" src={this.props.imgPath} style={{ maxHeight: '145px', maxWidth: '113px' }} /></p>
                    <Card.Body className='Card-Body'>
                        <Card.Title className="card-title">{this.props.name}</Card.Title>
                    </Card.Body>
                    <Card.Footer>
                        {this.props.job}
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}

export default PictureCard;
