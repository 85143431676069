import React from "react";
import Title from "../images/title.png"
import PageTitle from "./PageTitle";
import FetchMembers from "./FetchMembers.js";

function Trombinoscope() {
  return (
    <div className="Trombinoscope App-header">
      <PageTitle Title={Title} text="Le Trombi" />
      <FetchMembers page='Trombinoscope' />
    </div>
  );
}

export default Trombinoscope;