import React from "react";
import Title from "../images/title.png"
import PageTitle from "./PageTitle";
import img1 from "../images/Concession/img1.JPG"
import img2 from "../images/Concession/img2.JPG"
import img3 from "../images/Concession/img3.JPG"
import img4 from "../images/Concession/img4.JPG"
import img5 from "../images/Concession/img5.JPG"
import img6 from "../images/Concession/img6.JPG"
import img7 from "../images/Concession/img7.JPG"
import img8 from "../images/Concession/img8.JPG"
import img9 from "../images/Concession/img9.JPG"
import { Container, Row, Image, Col } from "react-bootstrap";

function Concession() {
  return (
    <div className="Concession App-header">
      <PageTitle Title={Title} text="Concession" />
      <Container fluid>
        <Row>
          <Image src={img1} />
        </Row>
        <Row>
          <p>Ouverte depuis décembre 1998 et en 2013 ouverture de la nouvelle concession à Cagnes sur Mer</p>
        </Row>
        <Row>
            <Image fluid src={img2} />
        </Row>
        <Row>
          <p>Pour vous assister et vous conseiller, une équipe sympathique et compétente</p>
        </Row>
        <Row>
            <Image fluid src={img3} />
        </Row>
        <Row>
            <Image fluid src={img4} />
        </Row>
        <Row>
            <Image fluid src={img5} />
        </Row>
        <Row>
          <Col className="row align-items-center" xs={6} >
            <p>
              <a href="http://www.harley-davidson-nice.com/">http://www.harley-davidson-nice.com </a><br />
              <a href="mailto:hd-factory@wanadoo.fr"><font size="4">hd-factory@wanadoo.fr</font></a>
            </p>
          </Col>
          <Col className="row align-items-center" xs={6}>
            <Image src={img6} />
            <a href={img6} target="_blank" rel="noopener noreferrer">Cliquez ici pour agrandir le plan</a>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <p>Philou, l'homme derrière la concession ...</p>
            </Row>
            <Row>
              <Col>
                <Image src={img7} />
              </Col>
              <Col>
                <Image src={img8} />
              </Col>
            </Row>
            <Row>
              <p>Philou est également un passionné des vieilles motos</p>
            </Row>
          </Col>
          <Col>
            <p>
              Né à Cannes le 27 septembre 1960, Philippe EVARD, plus connu sous le pseudo de Philou, a hérité de la passion des motos transmise par son père qui,<br />
              malgrès son age, continue de se déplacer régulièrement au guidon de sa motocyclette.
            </p>
          </Col>
        </Row>
        <Row>
          <p>
            Après avoir fait ses armes sur de vieilles motos anglaises, Philou acquiert sa première Harley en 1977.<br />Il s'agissait d'un Sportster 1000 XLH qui sera suivi en 1980 par un Wide Glide à moteur Shovelhead, un des tout premier modèles custom construit par Milwaukee.<br />Cette moto figure d'ailleurs toujours en bonne place dans la collection qu'il a constituée au fil des années.<br /><br />Après avoir travaillé pour l'Aerospatiale, Philou décide en 1987 de voler de ses propres ailes et de se consacrer entièrement aux Harley-Davidson en ouvrant sa propre boutique à Fréjus.<br />À cette époque, il n'a pas encore le droit de faire usage du fameux "Bar and Shield".<br /><br />Toutefois, la qualité de son travail est très vite remarquée par le District Manager de la région qui lui propose, en 1990, de devenir "garagiste agréé" Harley-Davidson.<br />Ce court intermède aboutit fin 1991 à l'obtention du statut de concessionnaire.<br />Dès lors, Philou n'aura qu'un seul objectif : promouvoir les intérêts de la marque.<br /><br />En décembre 1998, Philou ouvre une nouvelle concession à Nice.<br />
          </p>
          <Image fluid src={img9} />
          <p>
            Bienvenue Philou parmi nous
          </p>
        </Row>
      </Container >
    </div >
  );
}

export default Concession;