import axios from 'axios';
import PageTitle from "./PageTitle";
import Title from "../images/title.png"
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import SouvenirsViewer from "./SouvenirsViewer";

class Souvenirs extends Component {
  constructor() {
    super();
    this.state = {
      years: [],
      balades: []
    }
  }

  jsonLoader(type, url) {
    switch (type) {
      case "year":
        axios.get(url)
        .then(res => {
          const years = res.data;
          this.setState({ years });
          return true;
        });
        break;
      case "balades":
        axios.get(url)
        .then(res => {
          const balades = res.data;
          this.setState({ balades });
          return true;
        });
        break;
    
      default:
        break;
    }
  }

  componentDidMount(params) {
    const urlYear = process.env.PUBLIC_URL + '/data/events/years.json';
    if (this.props.year !== undefined) {
      const urlBalades = process.env.PUBLIC_URL + '/data/events/' + this.props.year + '/balades.json';
      this.jsonLoader("balades", urlBalades);
    }

    this.jsonLoader("year", urlYear);
  }


  render() {
    const { years, balades } = this.state

    if (this.props.year === undefined) {
      return (
        <div className="Souvenirs App-header">
          <div className="container">
            <div className="row align-items-center my-5">
              <div>
                <PageTitle Title={Title} text="Souvenirs" />
                {years.map((year) => (
                  <div key={'year ' + year.year} className="d-grid gap-2">
                    <Button variant="dark" size="lg" href={'/#/souvenirs/' + year.year}>{year.year}</Button>
                  </div>
                ))}
                <p><a href="https://www.hog-riviera.fr/old/Memories/souvenirs.htm" target="_blank" rel="noopener noreferrer">Les anciens souvenirs</a></p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (this.props.balade === undefined) {
        return (
          <div className="Souvenirs App-header">
            <div className="container">
              <div className="row align-items-center my-5">
                <div>
                  <PageTitle Title={Title} text="Souvenirs" />
                  <Button variant="dark" size="lg" href={'/#/souvenirs'}>Retour au choix de l'année</Button>
                  {balades.map((balade) => (
                    <div key={'balade ' + balade.id} className="d-grid gap-2">
                      <Button variant="dark" size="lg" href={'/#/souvenirs/' + this.props.year + '/' + balade.id}>{balade.nom}</Button>
                    </div>
                  ))}
  
                  <p><a href="https://www.hog-riviera.fr/old/Memories/souvenirs.htm" target="_blank" rel="noopener noreferrer">Les anciens souvenirs</a></p>
  
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="Souvenirs App-header">
            <div className="container">
              <div className="row align-items-center my-5">
                <div>
                  <PageTitle Title={Title} text="Souvenirs" />
                  <Button variant="dark" size="lg" href={'/#/souvenirs'}>Retour au choix de l'année</Button>
                  <Button variant="dark" size="lg" href={'/#/souvenirs/' + this.props.year + '/'}>Retour au choix de l'activité</Button>
                  <SouvenirsViewer year={this.props.year} balade= {this.props.balade} />
  
                  <p><a href="https://www.hog-riviera.fr/old/Memories/souvenirs.htm" target="_blank" rel="noopener noreferrer">Les anciens souvenirs</a></p>
  
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default Souvenirs;