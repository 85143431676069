import React from "react";

//todo

function Boutique() {
  return (
    <div className="boutique App-header">
      <div className="container">
        <div className="row align-items-center my-5">
          <div>
            <h1 className="font-weight-light">boutique</h1>
            <p>
              La boutique sera prochainement là.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Boutique;