import React from "react";
import PageTitle from "./PageTitle";
import MyDisplayPDF from "./MyDisplayPDF";
import Title from "../images/title.png";
import Iframe from 'react-iframe'

function Activites() {
  return (
    <div className="Bureau App-header">
      <PageTitle Title={Title} text="Calendrier" />
      <p>Road Planner</p>
      <MyDisplayPDF pdf='/data/doc/Calendrier.pdf' />
      <Iframe url="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23000000&ctz=Europe%2FParis&mode=AGENDA&showTitle=0&showNav=0&showDate=0&showCalendars=0&showTz=0&showTabs=0&showPrint=0&src=OTI5MGE4ZjIxZjk0MmNiNGZkNGFjMmMwZDgwNDUwMWM0MmEzNGZjNzJiOGIwMjExYjk5ZGExMTE3Y2M4MmM3YkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%239E69AF"
      // <Iframe url="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23282c34&ctz=Europe%2FParis&mode=AGENDA&showTitle=0&showNav=0&showDate=0&showCalendars=0&showTz=0&showTabs=0&showPrint=0&src=OTI5MGE4ZjIxZjk0MmNiNGZkNGFjMmMwZDgwNDUwMWM0MmEzNGZjNzJiOGIwMjExYjk5ZGExMTE3Y2M4MmM3YkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%239E69AF"
        width="100%"
        height={ window.innerHeight - 500 + 'vh' }
        id=""
        className=""
        display="block"
        position="relative"/>
    </div>
  );
}

export default Activites;