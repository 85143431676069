import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import logo from '../images/chapter01.png';
import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import '../App.css';


function Navigation() {
    const history = useHistory();
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expanded={expanded} expand="lg" bg="#000000" variant="dark" sticky="top">
            <Container>
                <Navbar.Brand onClick={() => { history.push("/"); setExpanded(false) }}>
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    RIVIERA CHAPTER
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                    <Nav className="justify-content-end" >
                        <Nav.Link onClick={() => { history.push("/bureau"); setExpanded(false) }}>Bureau</Nav.Link>
                        <Nav.Link onClick={() => { history.push("/contacts"); setExpanded(false) }}>Contacts</Nav.Link>
                        <Nav.Link onClick={() => { history.push("/trombinoscope"); setExpanded(false) }}>Trombinoscope</Nav.Link>
                        <Nav.Link onClick={() => { history.push("/concession"); setExpanded(false) }}>Concession</Nav.Link>
                        <NavDropdown title="Balades" id="collasible-nav-dropdown" menuVariant="dark">
                            <NavDropdown.Item onClick={() => { history.push("/activites"); setExpanded(false) }}>Calendrier</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { history.push("/souvenirs"); setExpanded(false) }}>Souvenirs</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Documents Chapter" id="collasible-nav-dropdown" menuVariant="dark">
                            <NavDropdown.Item onClick={() => { history.push("/adhesion"); setExpanded(false) }}>Adhesion</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { history.push("/consignes"); setExpanded(false) }}>Consignes de Sécurité</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { history.push("/CharteHOG"); setExpanded(false) }}>Charte HOG</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { history.push("/reglement"); setExpanded(false) }}>Règlement Intérieur</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { history.push("/statuts"); setExpanded(false) }}>Statuts</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={() => { history.push("/boutique"); setExpanded(false) }}>Boutique</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { history.push("/liensutiles"); setExpanded(false) }}>Liens Utiles</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default withRouter(Navigation);