import axios from 'axios';
import React, { Component } from 'react';
import PictureCard from "./PictureCard.js";
import { Row, Col, Container, Card } from "react-bootstrap";

class FetchMembers extends Component {
    constructor() {
        super();
        this.state = {
            mbrs: []
        }
    }

    componentDidMount(params) {
        const url = process.env.PUBLIC_URL + '/data/mbrs/mbrs.json';

        axios.get(url)
            .then(res => {
                const mbrs = res.data;
                this.setState({ mbrs });
                return true;
            });
    }

    sortRole(retManagement) {
        const returnArray = [];
        retManagement.forEach(mbr => {
            if (mbr.role.management === 'Director') {
                returnArray.push(mbr);
            }
        });
        retManagement.forEach(mbr => {
            if (mbr.role.management === 'Assistant Director') {
                returnArray.push(mbr);
            }
        });
        retManagement.forEach(mbr => {
            if (mbr.role.management === 'Trésorier') {
                returnArray.push(mbr);
            }
        });
        retManagement.forEach(mbr => {
            if (mbr.role.management === 'Secrétaire') {
                returnArray.push(mbr);
            }
        });
        retManagement.forEach(mbr => {
            if (mbr.role.management === ' ') {
                returnArray.push(mbr);
            }
        });
        return(returnArray);
    }

    render() {
        const { mbrs } = this.state
        let retManagement = [];
        const retVariousTeams = [];
        const retRoadCaptain = [];
        const retSafetyOfficer = [];

        if (mbrs) {
            if (this.props.page === 'Bureau') {
                this.state.mbrs.map(
                    mbrs => {
                        if (mbrs.role !== false && mbrs.active !== false) {
                            if (mbrs.role.management !== false) {
                                retManagement.push(mbrs);
                            }
                            if (mbrs.role.VariousTeams !== false) {
                                retVariousTeams.push(mbrs);
                            }
                            if (mbrs.role.RoadCaptain !== false) {
                                retRoadCaptain.push(mbrs);
                            }
                            if (mbrs.role.SafetyOfficer !== false) {
                                retSafetyOfficer.push(mbrs);
                            }
                        }
                        return (null);
                    }
                )
                retManagement = this.sortRole(retManagement);
                return (
                    <Container fluid>
                        <Row className="g-4">
                            <h1>management</h1>
                            {retManagement.map(mbrs => <Col key={'management' + mbrs.id} ><PictureCard imgPath={process.env.PUBLIC_URL + '/data/mbrs/img/' + mbrs.id + '.jpg'} name={mbrs.nameToDisplay} job={mbrs.role.management} /></Col>)}
                        </Row>
                        <Row className="g-4">
                            <h1>VariousTeams</h1>
                            {retVariousTeams.map(mbrs => <Col key={'VariousTeams' + mbrs.id} ><PictureCard imgPath={process.env.PUBLIC_URL + '/data/mbrs/img/' + mbrs.id + '.jpg'} name={mbrs.nameToDisplay} job={mbrs.role.VariousTeams} /></Col>)}
                        </Row>
                        <Row className="g-4">
                            <h1>RoadCaptain</h1>
                            {retRoadCaptain.map(mbrs => <Col key={'RoadCaptain' + mbrs.id} ><PictureCard imgPath={process.env.PUBLIC_URL + '/data/mbrs/img/' + mbrs.id + '.jpg'} name={mbrs.nameToDisplay} /></Col>)}
                        </Row>
                        <Row className="g-4">
                            <h1>SafetyOfficer</h1>
                            {retSafetyOfficer.map(mbrs => <Col key={'SafetyOfficer' + mbrs.id} ><PictureCard imgPath={process.env.PUBLIC_URL + '/data/mbrs/img/' + mbrs.id + '.jpg'} name={mbrs.nameToDisplay} /></Col>)}
                        </Row>
                    </Container>
                );
            } else if (this.props.page === 'Trombinoscope') {
                return (
                    <Container fluid>
                        <Row className="g-4">
                            {this.state.mbrs.map(mbrs => {
                                if (mbrs.active !== false) {
                                    return (<Col key={'Trombinoscope' + mbrs.id} ><PictureCard imgPath={process.env.PUBLIC_URL + '/data/mbrs/img/' + mbrs.id + '.jpg'} name={mbrs.nameToDisplay} /></Col>);
                                } else {
                                    return (null);
                                }
                            })}
                        </Row>
                    </Container>
                );
            } else if (this.props.page === 'Contact') {

                this.state.mbrs.map(
                    mbrs => {
                        if (mbrs.role !== false) {
                            if (mbrs.role.management !== false) {
                                retManagement.push(mbrs);
                            }
                        }
                        return (null);
                    }
                )
                retManagement = this.sortRole(retManagement);
                return (
                    <Container fluid>
                        <Row className="g-4">
                            {retManagement.map(mbrs =>
                                <div className='App-card' key={'Contact' + mbrs.id}>
                                    <Card border="warning" style={{ width: '20rem', backgroundColor: 'black' }} >
                                        <Row>
                                            <Col>
                                                <Card.Img variant="top" src={process.env.PUBLIC_URL + '/data/mbrs/img/' + mbrs.id + '.jpg'} style={{ maxHeight: '145px', maxWidth: '113px' }} />
                                                <Card.Body>
                                                    <Card.Title>{mbrs.nameToDisplay}</Card.Title>
                                                    <Card.Subtitle>
                                                        {mbrs.role.management}
                                                    </Card.Subtitle>
                                                    <Card.Text>
                                                        {mbrs.phone !== '' ? 'Téléphone : '+mbrs.phone : ''}<br />
                                                        Mail : {mbrs.email}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </Container>
                );
            }
        }
    }
}

export default FetchMembers;
