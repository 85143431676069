import React, { Component } from "react";
import { Image } from "react-bootstrap";

class PageTitle extends Component {

    render() {
        return (
            <div className="Imgcontainer">
                <Image className="Imgresponsive" src={this.props.Title}/>
                <h1 className="Textimg">{this.props.text}</h1>
            </div>
        );
    }
}

export default PageTitle;
