import axios from 'axios';
import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class SouvenirsViewer extends Component {
  constructor() {
    super();
    this.state = {
      picData: []
    }
  }

  componentDidMount(params) {
    const urlPicData = process.env.PUBLIC_URL + '/data/events/' + this.props.year + '/' + this.props.balade + '/data.json';

    axios.get(urlPicData)
    .then(res => {
      const picData = res.data;
      this.setState({ picData });
      return true;
    });
  }

  render() {
      const { picData } = this.state
      const baseURL = process.env.PUBLIC_URL + '/data/events/' + this.props.year + '/' + this.props.balade + '/';


      return <div>
        <Carousel autoPlay interval={5000} infiniteLoop showIndicators={false} showThumbs={false} dynamicHeight={true}>
          {picData.map((slide) => (
            <div key={slide.id}>
              <img src={baseURL + slide.id} alt='' />
            </div>
          ))}
        </Carousel>
      </div>
  }
}

export default SouvenirsViewer;
