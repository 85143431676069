import React from "react";

function Home() {
    return (
        <div className="App">
            <div className="App-header">
                <div className="Home-txt">
                    <p>
                        Bonjour et bienvenue sur le site du <b>RIVIERA CÔTE D'AZUR CHAPTER FRANCE</b>.
                        <br />
                        Fondé en 1991, nous sommes le plus vieux mais aussi le plus actif Chapter du <b>H</b>arley <b>O</b>wner <b>G</b>roup de France.
                        <br />
                        En association avec la Concession HD FACTORY située au <a href="https://www.google.com/maps/place/16+Chem.+des+Espartes,+06800+Cagnes-sur-Mer/@43.6635273,7.1741084,17z/data=!3m1!4b1!4m6!3m5!1s0x12cdd3de7b8e4fe1:0x35b11470cd73daee!8m2!3d43.6635273!4d7.1741084!16s%2Fg%2F11bz_49g26?entry=ttu" target="blank">16, chemin des espartes à Cagnes-sur-mer</a>, nous organisons pour nos membres de nombreux événements (soirée, repas,...) et balades locales, nationales, Européennes voir internationales!
                        <br />
                        Le climat avantageux de la côte d'azur, la bonne humeur et l'esprit de camaraderie ambiante sont notre force pour profiter de nos motos HARLEY DAVIDSON.
                        <br />
                        Alors n'hésitez pas à venir nous rencontrer à la Concession à notre permanence tous les samedi matin pour vous inscrire.
                        <br />
                        <br />
                        Bonne visite,
                        <br />
                        Maurizio RAFFAELLI
                        <br />
                        Directeur RCACF
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Home;
