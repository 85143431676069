import React from 'react';
import { isMobile } from 'react-device-detect';

export default function MyDisplayPDF(props) {
  if (isMobile) {
    return (<p className="Home-txt">Vous ne pouvez pas afficher le document pdf dans la page <a href={props.pdf} target="_blank" rel="noopener noreferrer">vers le document PDF!</a></p>);
  }
  return (
  <object data={props.pdf} type="application/pdf" width="100%" height={window.innerHeight - 110 + 'vh'}>
    <p className="Home-txt">Vous ne pouvez pas afficher le document pdf dans la page <a href={props.pdf} target="_blank" rel="noopener noreferrer">vers le document PDF!</a></p>
  </object>
);
}
